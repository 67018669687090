<template>
  <div
    class="container-main casino-container-user-register-box csn-hurricane-casino csn-registration-success"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="page-box">
            <h1>{{ t('check_your_email') }}</h1>
            <p class="description">
              {{ t('description') }}
            </p>
            <p>
              <Route
                :to="{ name: routeName.homepage }"
                class="btn casino-btn casino-btn-default casino-btn-main-menu casino-main-menu-btn"
              >
                {{ t('finish') }}
              </Route>
            </p>
            <div class="line"></div>
            <p class="links">
              {{ t('need_help') }}
              <Route :to="{ name: routeName.resendEmail }">
                {{ t('click_here') }}
              </Route>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouteName, REGISTRATION_SUCCESS } from '@/constants'

export default {
  name: REGISTRATION_SUCCESS,
  components: {
    Route: () => import('@/components/Route'),
  },
  computed: {
    routeName() {
      return {
        homepage: RouteName.HOME,
        resendEmail: RouteName.RESEND_YOUR_CONFIRMATION_EMAIL,
      }
    },
    t() {
      return this.$createComponentTranslator(REGISTRATION_SUCCESS)
    },
  },
}
</script>
